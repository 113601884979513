function App() {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      height: '100%',
    }}>
      <h1>Coming soon!</h1>
    </div>
  );
}

export default App;
